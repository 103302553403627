import Vue from 'vue'
import VueRouter from 'vue-router'
import config from '../../config'
import store from '../store'
import { VueEasyJwt } from 'vue-easy-jwt'

const jwt = new VueEasyJwt()
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/',
    name: 'Inicio',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/jugadoras',
    name: 'Jugadoras',
    component: () => import(/* webpackChunkName: "jugadoras" */ '../views/Jugadoras.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/equipos',
    name: 'Equipos',
    component: () => import(/* webpackChunkName: "equipos" */ '../views/Equipos.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/entidades',
    name: 'Entidades',
    component: () => import(/* webpackChunkName: "entidades" */ '../views/Entidades.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/delegadas',
    name: 'Delegadas',
    component: () => import(/* webpackChunkName: "delegadas" */ '../views/Delegadas.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/pagos',
    name: 'Pagos',
    component: () => import(/* webpackChunkName: "pagos" */ '../views/Pagos.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/torneos',
    name: 'Torneos',
    component: () => import(/* webpackChunkName: "torneos" */ '../views/Torneos.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/sanciones',
    name: 'Sanciones',
    component: () => import(/* webpackChunkName: "sanciones" */ '../views/Sanciones.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/directores-tecnicos',
    name: 'Direcotes/Ayudantes Técnicos',
    component: () => import(/* webpackChunkName: "tecnicos" */ '../views/Tecnicos.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/arbitros',
    name: 'Árbitros',
    component: () => import(/* webpackChunkName: "arbitros" */ '../views/Arbitros.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/mesa-entrada',
    name: 'Mesa de Entrada',
    component: () => import(/* webpackChunkName: "mesa-entrada" */ '../views/MesaEntrada.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/programacion',
    name: 'Programación de Torneos',
    component: () => import(/* webpackChunkName: "programacion" */ '../views/Programacion.vue'),
    meta: {
      requireAuth: true,
      rutaProtegida: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (is_auth()) {
      if (to.matched.some(record => record.meta.rutaProtegida)) {
        try {
          store.state.loading = true

          const res = await fetch(`${config.BASE_URL}/user/rutaValida?path=${to.path}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': JSON.parse(localStorage.getItem('token'))
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            next()
          } else {
            next({ path: '/' })
            store.dispatch('show_swal', {
              title: data.message,
              icon: 'error'
            })
          }

        } catch (error) {
          next({ path: '/' })
          store.dispatch('show_swal', {
            title: error.message,
            icon: 'error'
          })
        } finally {
          store.state.loading = false
        }
      } else {
        next()
      }
    } else {
      if (JSON.parse(localStorage.getItem('token'))) {
        store.dispatch('show_swal', {
          title: 'La sesión actual expiró',
          icon: 'info'
        })
      }
      await store.dispatch('logout')
      next({ path: '/login' })
    }
  } else {
    next()
  }
  window.scrollTo(0, 0)
})

function is_auth () {
  const token = JSON.parse(localStorage.getItem('token'))
  return !jwt.isExpired(token)
}

export default router
