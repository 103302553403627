<template>
  <v-app>
    <v-app-bar
      v-if="is_logged && $route.name !== 'Login'"
      :color="$store.state.menu.find(me => me.path == $route.path) ? $store.state.menu.find(me => me.path == $route.path).color : 'primary'"
      dense
      dark
      app
    >
      <v-tooltip
        v-if="$store.state.menu.find(me => me.path == $route.path) != undefined"
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
            @click="vovler()"
          >
            <v-icon>fas fa-arrow-left</v-icon>
          </v-btn>
        </template>
        <span>Volver atrás</span>
      </v-tooltip>
      <div style="cursor: default; font-size: 20px;">
        AMVS | {{ $store.state.menu.find(me => me.path == $route.path) ? $store.state.menu.find(me => me.path == $route.path).title : 'Inicio' }}
      </div>
      <v-spacer></v-spacer>
      <v-menu offset-y left>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            title="Ver opciones del usuario"
            text
            :icon="$vuetify.breakpoint.xs"
          >
            <v-icon size="24" :left="!$vuetify.breakpoint.xs">
              fas fa-user-circle
            </v-icon>
            <span v-if="!$vuetify.breakpoint.xs" class="ml-2">
              {{ $store.state.username }}
            </span>
          </v-btn>
        </template>
        <v-list class="py-2">
          <v-list-item
            class="my-n1"
            style="cursor: pointer"
            @click.stop="dialog_clave = true"
          >
            <v-list-item-title>
              <v-icon left small>fas fa-key</v-icon>
              Cambiar clave
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
            @click="$store.dispatch('logout', true)"
          >
            <v-icon>fas fa-sign-out-alt</v-icon>
          </v-btn>
        </template>
        <span>Cerrar sesión</span>
      </v-tooltip>
    </v-app-bar>
    <v-main :style="imagen">
      <v-container fluid>
        <v-scroll-x-transition hide-on-leave>
          <router-view/>
        </v-scroll-x-transition>
      </v-container>
    </v-main>
    <Loader/>
    <CambiarClave v-model="dialog_clave"/>
  </v-app>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Loader from './components/utils/Loader.vue'
import CambiarClave from './components/utils/CambiarClave.vue'

export default {
  data () {
    return {
      dialog_clave: false
    }
  },
  async created () {
    await this.loadLocalStorage()
  },
  computed: {
    ...mapState(['generic_swal']),
    ...mapGetters(['is_logged']),
    imagen (){
      return [
        this.$route.name === 'Login' ? {
          'background-image' : `url(${require('./assets/fondo.jpg')})`,
          'background-position' : 'center',
          'background-repeat' : 'no-repeat',
          'height' : '500px',
          'background-size' : 'cover'
        } : {}
      ]
    }
  },
  components: {
    Loader,
    CambiarClave
  },
  watch: {
    generic_swal: {
      handler: function () {
        this.$swal.fire(this.generic_swal)
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(['loadLocalStorage']),
    vovler () {
      if (window.history !== null) this.$router.go(-1) 
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.swal2-popup {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
</style>
