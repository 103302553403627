import config from '../../config'
import store from '../store'

export default {
  namespaced: true,
  state: {
    tipos_sancion: []
  },
  mutations: {
    set_tipos (state, payload) {
      state.tipos_sancion = payload
    }
  },
  actions: {
    async get_tipos ({ state, commit }) {
      if (state.tipos_sancion.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/sanciones/tipos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) commit('set_tipos', data.data)
          else throw new Error(data.message)

        } catch (error) {
          commit('set_tipos', [])
          store.dispatch('show_swal', {
            icon: 'error',
            title: 'Ocurrio un error al obtener los tipos de sanciones',
            text: error.message
          })
        }
      }
    },
    async buscar ({}, fto) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/sanciones/search?descripcion=${fto.descripcion}&tipo=${fto.tipo}&concepto=${fto.concepto}&motivo=${fto.motivo}&entidad=${fto.entidad}&codigo=${fto.codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async nueva ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/sanciones`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    }
  }
}