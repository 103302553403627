import config from '../../config'
import store from '../store'

export default {
  namespaced: true,
  state: {
    estados_arbitro: [],
    arbitros_activos: []
  },
  mutations: {
    set_estados (state, payload) {
      state.estados_arbitro = payload
    },
    set_activos (state, payload) {
      state.arbitros_activos = payload
    }
  },
  actions: {
    async get_estados ({ state, commit }) {
      if (state.estados_arbitro.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/arbitros/estados`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) commit('set_estados', data.data)
          else throw new Error(data.message)

        } catch (error) {
          commit('set_estados', [])
          store.dispatch('show_swal', {
            icon: 'error',
            title: 'Ocurrio un error al obtener los estados de los árbitros',
            text: error.message
          })
        }
      }
    },
    async buscar ({}, fto) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/arbitros/search?apellido=${fto.apellido}&nombre=${fto.nombre}&num_doc=${fto.num_doc}&estado=${fto.estado}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async nuevo ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/arbitros`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async editar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/arbitros/${payload.id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async baja ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/arbitros/${id}/baja`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async alta ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/arbitros/${id}/alta`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async activos ({ commit }) {
      try {
        const res = await fetch(`${config.BASE_URL}/arbitros/activos`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': store.state.token
          }
        })
  
        const data = await res.json()
  
        if (data.exito == 1) commit('set_activos', data.data)
        else throw new Error(data.message)

      } catch (error) {
        commit('set_activos', [])
        store.dispatch('show_swal', {
          icon: 'error',
          title: 'Ocurrio un error al obtener los árbitros activos',
          text: error.message
        })
      }
    },
    async sanciones ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/arbitros/${id}/sanciones`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    }
  }
}