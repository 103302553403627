import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import router from '../router'
import config from '../../config'
import { parseDate } from '../utils'
import { VueEasyJwt } from "vue-easy-jwt"
import { Encrypt, Decrypt } from "../util/aes"
// modulos
import jugadoras from '../modules/jugadoras'
import entidades from '../modules/entidades'
import equipos from '../modules/equipos'
import delegadas from '../modules/delegadas'
import pagos from '../modules/pagos'
import torneos from '../modules/torneos'
import sanciones from '../modules/sanciones'
import tecnicos from '../modules/tecnicos'
import arbitros from '../modules/arbitros'
import mesa from '../modules/mesa'
import programacion from '../modules/programacion'

const jwt = new VueEasyJwt()
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    username: '',
    loading: false,
    tipo_entidad: [],
    menu: [],
    generic_swal: {},
    global_rules: {
      required: value => !!value || 'Campo requerido',
      required_array: value => {
        if (Array.isArray(value)) {
          return value.length == 0 ? 'Campo requerido' : true
        } else {
          return 'Campo requerido'
        }
      },
      fecha: value => {
        if (value) {
          if (value.length == 10) {
            if (moment(parseDate(value)).isValid()) return true
            else return 'Fecha inválida'
          } else {
            return 'Formato inválido'
          }
        } else {
          return true
        }
      }
    }
  },
  getters: {
    is_logged(state) {
      return !jwt.isExpired(state.token)
    }
  },
  mutations: {
    set_token (state, payload) {
      state.token = payload
      localStorage.setItem('token', JSON.stringify(payload))
      if (payload) {
        const token = jwt.decodeToken(payload)
        state.username = Decrypt(token.credenciales.usuario)
      } else {
        state.username = ''
      }
    },
    set_menu (state, payload) {
      state.menu = payload
      localStorage.setItem('menu', JSON.stringify(payload))
    },
    set_swal (state, payload) {
      state.generic_swal = payload
    },
    set_entidad (state, payload) {
      state.tipo_entidad = payload
    }
  },
  actions: {
    async login ({ commit }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/user/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              usuario: Encrypt(payload.user.toUpperCase()),
              clave: Encrypt(payload.password)
            })
          })

          const data = await res.json()

          if (data.exito === 1) {
            commit('set_token', data.token)
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async logout({ commit }, redirect) {
      commit('set_token', null)
      commit('set_menu', [])
      if (redirect) return router.push({ path: "/login" })
    },
    async get_menu ({ commit, dispatch, state }) {
      try {
        const res = await fetch(`${config.BASE_URL}/user/menu`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': state.token
          }
        })

        const data = await res.json()

        if (data.exito == 1) commit('set_menu', data.data)
        else throw new Error(data.message)

      } catch (error) {
        commit('set_menu', [])
        dispatch('show_swal', {
          icon: 'error',
          title: 'Ocurrio un error al obtener el menu',
          text: error.message
        })
      }
    },
    async cambiar_clave ({ state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/user/password`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.token
            },
            body: JSON.stringify({
              clave_vieja: Encrypt(payload.vieja),
              clave_nueva: Encrypt(payload.nueva)
            })
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_entidad ({ state, commit }) {
      if (state.tipo_entidad.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/entidad`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) commit('set_entidad', data.data)
          else throw new Error(data.message)

        } catch (error) {
          commit('set_entidad', [])
          store.dispatch('show_swal', {
            icon: 'error',
            title: 'Ocurrio un error al obtener las entidades',
            text: error.message
          })
        }
      }
    },
    async get_persona ({ state }, num_doc) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/persona?num_doc=${num_doc}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    show_swal ({ commit }, payload) {
      commit('set_swal', payload)
    },
    loadLocalStorage({ commit }) {
      commit('set_token', JSON.parse(localStorage.getItem('token')))
      commit('set_menu', JSON.parse(localStorage.getItem('menu')))
    }
  },
  modules: {
    jugadoras,
    entidades,
    equipos,
    delegadas,
    pagos,
    torneos,
    sanciones,
    tecnicos,
    arbitros,
    mesa,
    programacion
  }
})
